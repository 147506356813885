<template>
  <base-section
    id="features"
    class="accentzero"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
              :title="$t(card.title)"
              :text="$t(card.text)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-folder-multiple-image',
          title: 'landing-features.title1',
          text: 'landing-features.text1',
        },
        {
          icon: 'mdi-desktop-mac-dashboard',
          title: 'landing-features.title2',
          text: 'landing-features.text2',
        },
        {
          icon: 'mdi-chart-bar-stacked',
          title: 'landing-features.title3',
          text: 'landing-features.text3',
        },
        {
          icon: 'mdi-application-export',
          title: 'landing-features.title4',
          text: 'landing-features.text4',
        },
      ],
    }),
  }
</script>
